<template>
  <div class="UniversityRankings">
    <div class="minw homeDivCss">
      <div class="bottom">
        <div style="display:flex;align-items: center; justify-content:space-around;font-size: 18px;margin:10px opx;">
          <p @click="tabChange(item.id)" class="tabsActive indexFz" :class="item.id == queryParams.code ? 'active' : ''" v-for="(item,index) in schoolArr" :key="index">{{ item.schoolName}}
          </p>
        </div>
        <!-- table表格 -->
        <el-table :data="tableData" class="threeTable" :header-cell-style="{
                backgroundColor: '#f4f4f4',
                color: 'black',
                fontSize: '17px',
                textAlign: 'center',
              }" :cell-style="{
  textAlign: 'center',
}" v-loading="loading">
          <el-table-column prop="collegeName" label="学校名称" >
          </el-table-column>
          <el-table-column prop="yearWithRanking" label="年份" >
          </el-table-column>
          <el-table-column prop="aaRankings" label="校友会排名" v-if="queryParams.code == 1"></el-table-column>
          <el-table-column prop="bcuRanking" label="软科排名" v-if="queryParams.code == 2"></el-table-column>
          <el-table-column prop="ussRankings" label="usnews排名" v-if="queryParams.code == 3"></el-table-column>
          <!-- <el-table-column prop="theRankings" label="泰晤士" v-if="queryParams.code == 4"></el-table-column> -->
          <el-table-column prop="qsRankings" label="QS排名" v-if="queryParams.code == 5"></el-table-column>
          <el-table-column prop="gdiRanking" label="GDI排名" v-if="queryParams.code == 6"></el-table-column>

        </el-table>
        <pagination :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import { REQcollegeRang } from "@/api/homeIndex.js";
export default {
  name: "UniversityRankings",
  data() {
    return {
      schoolSrc: require("@/assets/imgs/schoolRnnkimg.png"),
      schoolArr: [
        { schoolName: "校友会排名", id: 1 },
        { schoolName: "软科排名", id: 2 },
        { schoolName: "usnews排名", id: 3 },
        // { schoolName: "泰晤士", id: 4 },
        { schoolName: "QS排名", id: 5 },
        { schoolName: "GDI排名", id: 6 },
      ],
      queryParams: {
        yearWithRanking: "",
        code: "1",
        pageNum: 1,
        pageSize: 50,
      },
      loading: true,
      tableData: [],
      total: 0,
    };
  },
  created() {
    // this.queryParams.yearWithRanking = new Date().getFullYear()-1;
    this.getList();
    // window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    tabChange(id) {
      this.queryParams.code = id;
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 50;
      this.getList();
    },
    getList() {
      const self = this;
      self.loading = true;
      REQcollegeRang(this.queryParams).then((res) => {
        if (res.code == 200) {
          self.tableData = res.rows;
          self.loading = false;
          self.total = res.total
        }
      });
    },
    handleScroll(){
         const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      // 根据滚动位置进行逻辑处理
      if (scrollPosition > 100) { // 假设当滚动超过100px时...
        // 执行某些操作，比如显示一个返回顶部的按钮
      }
    }
  },
};
</script>

<style   lang="scss" scoped>
.UniversityRankings {
  user-select: none;
  background: white;
  .bottom {
  width: 800px;
    margin: 25px auto 0;
    .line {
      margin: 3px;
      height: 3px !important;
      width: 35px;
      background: #30c9b2;
      margin-bottom: 10px;
    }
    .title {
      font-weight: 900;
      font-size: 18px;
    }
    .schoolRank {
      display: flex;
      margin: 20px 0;
      align-items: center;
      p {
        font-size: 22px;
        margin: 10px;
      }
      p:hover {
        color: #30c9b2;
      }
    }
    .tabsActive{
      margin: 0 10px;
      width: 20%;
      text-align: center;
      border:1px solid #30c9b2;
      height: 46px;
      line-height: 46px;
       border-radius: 15px;
       font-weight: 900;
    }
    .tabsActive:hover {
      color: #30c9b2;
      cursor: pointer;
    }
    .active {
      text-align: center;
      background: #30c9b2;
      color: white;
    }
    .active:hover {
      color: white !important;
    }
  }
}
</style>
