var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"UniversityRankings"},[_c('div',{staticClass:"minw homeDivCss"},[_c('div',{staticClass:"bottom"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-around","font-size":"18px","margin":"10px opx"}},_vm._l((_vm.schoolArr),function(item,index){return _c('p',{key:index,staticClass:"tabsActive indexFz",class:item.id == _vm.queryParams.code ? 'active' : '',on:{"click":function($event){return _vm.tabChange(item.id)}}},[_vm._v(_vm._s(item.schoolName)+" ")])}),0),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"threeTable",attrs:{"data":_vm.tableData,"header-cell-style":{
                backgroundColor: '#f4f4f4',
                color: 'black',
                fontSize: '17px',
                textAlign: 'center',
              },"cell-style":{
  textAlign: 'center',
}}},[_c('el-table-column',{attrs:{"prop":"collegeName","label":"学校名称"}}),_c('el-table-column',{attrs:{"prop":"yearWithRanking","label":"年份"}}),(_vm.queryParams.code == 1)?_c('el-table-column',{attrs:{"prop":"aaRankings","label":"校友会排名"}}):_vm._e(),(_vm.queryParams.code == 2)?_c('el-table-column',{attrs:{"prop":"bcuRanking","label":"软科排名"}}):_vm._e(),(_vm.queryParams.code == 3)?_c('el-table-column',{attrs:{"prop":"ussRankings","label":"usnews排名"}}):_vm._e(),(_vm.queryParams.code == 5)?_c('el-table-column',{attrs:{"prop":"qsRankings","label":"QS排名"}}):_vm._e(),(_vm.queryParams.code == 6)?_c('el-table-column',{attrs:{"prop":"gdiRanking","label":"GDI排名"}}):_vm._e()],1),_c('pagination',{attrs:{"total":_vm.total,"page":_vm.queryParams.pageNum,"limit":_vm.queryParams.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.queryParams, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.queryParams, "pageSize", $event)},"pagination":_vm.getList}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }